.css-17lbogs-MuiButtonGroup-root .MuiButtonGroup-middleButton:hover {
    border-right-color: transparent;
}

.css-1ih592f .MuiButtonGroup-firstButton:hover, .css-1ih592f .MuiButtonGroup-middleButton:hover {
    border-right-color: transparent;
}

.css-17lbogs-MuiButtonGroup-root .MuiButtonGroup-firstButton:hover, .css-17lbogs-MuiButtonGroup-root .MuiButtonGroup-middleButton:hover {
    border-right-color: transparent;
}