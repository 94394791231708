.css-11zohuh-MuiSvgIcon-root {
    font-size: 0.8rem !important;
}
.css-1hbvpl3-MuiSvgIcon-root {
    font-size: 0.8rem  !important;
} 
.css-1iu0z9r-MuiResponsiveChart-container {
    margin-top: 15px;
    padding-top: 3px;
    position: relative;
    right: 0.8rem;
}
.css-150p3xs-MuiResponsiveChart-container {
  width: 230px;
  height: 120px;
}
.css-v5pmlp-MuiPaper-root{
  margin-top: 1rem;
}
  canvas {
    width: 100%;
    height: 130px;
    background: #f9f9f9;
    margin: 2rem auto;
    box-shadow: inset 0px 0px 25px -15px #000;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .wrapper {
    padding: 2rem;
  }
  .playlist {
    text-align: left;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .playlist-item {
    color: #062308;
    border-bottom: 1px solid #d8d5ccf2;
    border-top: 1px solid #d8d5cc2e;
    cursor: pointer;
    padding: 0.8em;
  }
  
  .playlist-item.selected {
    font-weight: bold;
  }
  
  .playlist-item:hover {
    background: #f2eee3;
  }
  
  .controls {
    text-align: left;
    display: flex;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 8px;
  }
/* Track styles */
input[type='range'] {
  -webkit-appearance: none;
  width: 30%;
  height: 8px;
  background-color: rgba(209, 206, 197, 1); /* Set your desired track color here */
  border-radius: 0px;
  outline: none;
  margin: 2px 2px 2px 0px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #F9F5EB;
  color: #164A1A;
  background-color: #164A1A; /* Set your desired thumb color here */
  border-radius: 50%;
  cursor: pointer;
}

/* Highlight styles */
input[type='range']::-moz-range-track {
  background: #164A1A; /* Set your desired highlight color here */
  color: #164A1A;
}

input[type='range']::-ms-fill-lower,
input[type='range']::-ms-fill-upper {
  background-color: #164A1A; /* Set your desired highlight color here */
}


/* Highlight styles */
input[type='range']::-moz-range-track {
  background-color: rgba(22, 74, 26, 1); /* Set your desired highlight color here */
}
